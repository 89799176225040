import request from '@/utils/http';
//goods===============================================================
export function addGoods_api(data) {
  return request({
    url: '/api/v2/seller/goodscommon',
    method: 'post',
    data
  })
}
export function getGoodsList_api(data) {
  return request({
    url: '/api/v2/seller/goodscommon',
    method: 'get',
    params: data
  })
}  
export function editGoods_api(id,data) {
  return request({
    url: '/api/v2/seller/goodscommon/'+id,
    method: 'put',
    data
  })
}
export function getGoods_api(id) {
  return request({
    url: '/api/v2/seller/goodscommon/'+id,
    method: 'get'
  })
}
export function upDownGoods_api(data) {
  return request({
    url: '/api/v2/seller/goodscommon/setting',
    method: 'put',
    data
  })
}

export function deleGoods_api(id) {
  return request({
    url: '/api/v2/seller/goodscommon/'+id,
    method: 'delete'
  })
}
//order=================================================================
export function changeROrder_api(id,data) {
  return request({
    url: '/api/v2/seller/order/'+id,
    method: 'put',
    data
  })
}
export function changeCredit_api(id,data) {
  return request({
    url: '/api/v2/seller/pointsorder/'+id,
    method: 'put',
    data
  })
}
export function getROrderList_api(data) {
  return request({
    url: '/api/v2/seller/order',
    method: 'get',
    params: data
  })
}
export function getOrder_api(order_id) {
  return request({
    url: '/api/v2/seller/order/'+order_id,
    method: 'get',
  })
}
export function getCreditList_api(data) {
  return request({
    url: '/api/v2/seller/pointsorder',
    method: 'get',
    params:data
  })
}
export function deleteOrder_api(data) {
  return request({
    url: '/api/v2/seller/order/'+data.order_id,
    method: 'delete',
    data
  })
}
export function changeOrder(data) {
  return request({
    url: '/api/v2/seller/order/setting',
    method: 'put',
    data
  })
}


//coupon =================================================================
export function getCouponList_api(data) {
  return request({
    url: '/api/v2/seller/coupon',
    method: 'get',
    params: data
  })
}
export function getCouponInfo_api(id,data) {
  return request({
    url: '/api/v2/seller/coupon/'+id,
    method: 'get',
    params:data
  })
}
export function addCoupon_api(data) {
  return request({
    url: '/api/v2/seller/coupon',
    method: 'post',
    data
  })
}

export function deleCoupon_api(id) {
  return request({
    url: '/api/v2/seller/coupon/'+id,
    method: 'delete'
  })
}

//banner====================================================================
export function getBannerList_api(data) {
  return request({
    url: '/api/v2/seller/banner',
    method: 'get',
    params: data
  })
}

export function deleteBanner_api(id) {
  return request({
    url: '/api/v2/seller/banner/'+ id,
    method: 'delete',
  })
}

export function addBanner_api(data) {
  return request({
    url: '/api/v2/seller/banner',
    method: 'post',
    data
  })
}

export function editBanner_api(id,data) {
  return request({
    url: '/api/v2/seller/banner/'+id,
    method: 'put',
    data
  })
}

//auth======================================================================
export function getAuthList_api(data) {
  return request({
    url: '/api/v2/seller/seller',
    method: 'get',
    params: data
  })
}
export function getAuth_api(id) {
  return request({
    url: '/api/v2/seller/seller/'+id,
    method: 'get'
  })
}
export function deleteAuth_api(id) {
  return request({
    url: '/api/v2/seller/seller/'+id,
    method: 'delete'
  })
}
export function addAuth_api(data) {
  return request({
    url: '/api/v2/seller/seller',
    method: 'post',
    data: data
  })
}
export function editAuth_api(data,id) {
  return request({
    url: '/api/v2/seller/seller/'+id,
    method: 'put',
    data: data
  })
}
//=creditShop=================================================================
export function addCreditGoods_api(data) {
  return request({
    url: '/api/v2/seller/pointsgoods',
    method: 'post',
    data
  })
}
export function getCreditGoodsList_api(data) {
  return request({
    url: '/api/v2/seller/pointsgoods',
    method: 'get',
    params: data
  })
}
export function editCreditGoods_api(id,data) {
  return request({
    url: '/api/v2/seller/pointsgoods/'+id,
    method: 'put',
    data
  })
}
export function deleCreditGoods_api(id) {
  return request({
    url: '/api/v2/seller/pointsgoods/'+id,
    method: 'delete',
    id
  })
}
//user==============================================================
export function getUserList_api(data) {
  return request({
    url: '/api/v2/seller/member',
    method: 'get',
    params:data
  })
}
export function editUser_api(data) {
  return request({
    url: '/api/v2/seller/member',
    method: 'post',
    data
  })
}


//credit=============================================================
export function getCredit_api(data){
  return request({
    url:'/api/v2/seller/saleconfig',
    method:'get',
    params:data
  })
}
export function editCredit_api(data,id){
  return request({
    url:'/api/v2/seller/saleconfig/'+id,
    method:'put',
    data:data
  })
}

//postman============================================================
export function getPostList_api(data) {
  return request({
    url: '/api/v2/seller/admin',
    method: 'get',
    params: data
  })
}
export function confirm_api(data) {
  return request({
    url: '/api/v2/seller/adminrecord',
    method: 'post',
    data
  })
}
export function getPost_api(id) {
  return request({
    url: '/api/v2/seller/admin/'+id,
    method: 'get'
  })
}
export function getPostInfo_api(data) {
  return request({
    url: '/api/v2/seller/adminrecord',
    method: 'get',
    params:data
  })
}
export function deletePost_api(id) {
  return request({
    url: '/api/v2/seller/admin/'+id,
    method: 'delete'
  })
}
export function addPost_api(data) {
  return request({
    url: '/api/v2/seller/admin',
    method: 'post',
    data: data
  })
}

//address==========================================================
export function addressList_api(data){
  return request({
    url:'/api/v2/seller/site',
    method:'get',
    params:data
  })
}
export function addaddress_api(data){
  return request({
    url:'/api/v2/seller/site',
    method:'post',
    data
  })
}
export function deleaddress_api(id){
  return request({
    url:'/api/v2/seller/site/'+id,
    method:'delete'
  })
}
//奶品种=========================================================
export function getmilkType_api(data){
  return request({
    url:'/api/v2/seller/milky',
    method:'get',
    params:data
  })
}
export function addmilkType_api(data){
  return request({
    url:'/api/v2/seller/milky',
    method:'post',
    data
  })
}
export function delemilkType_api(id){
  return request({
    url:'/api/v2/seller/milky/'+id,
    method:'delete'
  })
}
//奶点==============================================================
export function getmilkStore_api(data){
  return request({
    url:'/api/v2/seller/cream',
    method:'get',
    params:data
  })
}
export function addmilkStore_api(data){
  return request({
    url:'/api/v2/seller/cream',
    method:'post',
    data
  })
}
export function delemilkStore_api(id){
  return request({
    url:'/api/v2/seller/cream/'+id,
    method:'delete'
  })
}
//奶点记录=============================================================
export function getmilkRecord_api(data){
  return request({
    url:'/api/v2/seller/creammanage',
    method:'get',
    params:data
  })
}
export function addmilkRecord_api(data){
  return request({
    url:'/api/v2/seller/creammanage',
    method:'post',
    data
  })
}
export function delemilkRecord_api(id){
  return request({
    url:'/api/v2/seller/creammanage/'+id,
    method:'delete'
  })
}
//edit data=================================================================
export function getEdit_api(data){
  return request({
    url:'/api/v2/seller/update',
    method:'get',
    params:data
  })
}

export function getShipments_api(data){
  return request({
    url:'/v5/user/shipments',
    method:'get',
    params:data
  })
}

export function shipments_api(data){
  return request({
    url:'/v5/user/shipments',
    method:'post',
    data
  })
}

export function deliverShipments_api(id){
  return request({
    url:'/v5/user/shipments/'+id,
    method:'put',
  })
}

export function getShipmentsList_api(data){
  return request({
    url:'/v5/user/shipments',
    method:'get',
    params:data
  })
}
// 物料中心
export function getMaterialList_api(data){
  return request({
    url:'/v10/user/shipments',
    method:'get',
    params:data
  })
}

// 会员收货
export function getMemberSList_api(data,id){
  return request({
    url:'/v4/user/apply/'+id,
    method:'put',
    data
  })
}


// 收货
export function getOnleMemberSList_api(id){
  return request({
    url:'/v5/user/shipments/'+id,
    method:'put',

  })
}


// 审核详情
export function sharesChange_list_api(data) {
  return request({
    url: '/v11/user/sharesChange',
    method: 'get',
    params:data
  })
}
